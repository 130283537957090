.banner_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 190px;
}

.banner {
  width: 55%;
  text-align: center;
  background: #333333;
  color: #ffffff;
  margin: 5.6% auto;
  padding: 1%;
  box-sizing: border-box;
  border-radius: 30px / 30px;
}
.paymentBanner {
  width: 55%;
  text-align: center;
  margin: 5.6%;
  padding: 1%;
  box-sizing: border-box;
  border-radius: 30px / 30px;
}
.bannerTitle {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3.2rem;
  font-family: GalanoGrotesqueAlt-Bold;
}
.paymentBannerTitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 3.2rem;
  font-family: GalanoGrotesqueAlt-Bold;
}
.paymentBannerSubTitle {
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  font-family: GalanoGrotesqueAlt-Bold;
}
.bannerSubTitle {
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  .banner {
    width: 80%;
    border-radius: 10px 10px;
  }

  .bannerSubTitle {
    font-size: 0.65rem;
  }

  .bannerTitle {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .banner {
    padding: 3%;
  }
}
